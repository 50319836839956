
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



























































































.carousel.scrolling-gallery__carousel {
  --item-width: 29rem;
  --item-ratio: 0.935;
  --scrollbar-width: 0;

  margin-top: var(--spacing-m);

  ::v-deep .carousel__item {
    position: relative;
    width: var(--item-width);
    max-width: 80vw;
  }

  @include mq(l) {
    --slide-gap: 3rem;
    --item-width: 34rem;
    --item-ratio: 4/3;
  }
}

.scrolling-gallery__picture {
  aspect-ratio: var(--item-ratio);
}

.scrolling-gallery__item {
  display: flex;
  flex-direction: column-reverse;
}

.scrolling-gallery__item__title {
  @extend %ff-alt;
  @extend %fw-bold;

  margin-top: var(--spacing-xs);
  text-decoration: none;

  &::after {
    @include get-all-space;

    z-index: 1;
    content: '';
  }
}

.scrolling-gallery__item__picture {
  @extend %border-radius;

  position: relative;
  overflow: hidden;
  width: 100%;
  aspect-ratio: var(--item-ratio);

  ::v-deep img {
    transition: transform 0.3s $ease-out-cubic;
    will-change: transform;
  }

  .scrolling-gallery__item:hover &,
  .scrolling-gallery__item:focus-within & {
    ::v-deep img {
      transform: scale(1.05);
    }
  }
}
